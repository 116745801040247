import React from "react"
import PropTypes from "prop-types"
import SEO from "../components/seo"

import { graphql } from "gatsby"
import { LocaleContext } from "../components/layout"
import DetailView from "../components/Templates/detailView"
import Downloads from "../components/Downloads/downloads"
import publicationsAnimition from "../static/animations/publikationen.json"
import Animation from "../components/animation"

const Publikationen = ({
  data: { content, publications },
  pageContext: { locale },
  location,
}) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  return (
    <>
      <SEO
        title={"Publikationen"}
        description={content.data.seo_publikationen_beschreibung.text}
        keywords={
          content.data.seo_publikationen_keywords.text
            ? content.data.seo_publikationen_keywords.text.split(", ")
            : [`Gesundheit`, `Portfolio`, `Publikationen`]
        }
      />
      <DetailView>
        <section id="publikationen">
          <div className="container narrow gray wide two-cols">
            <div className="portfolio__animation">
              <Animation loop={true} animation={publicationsAnimition} />
            </div>
            <div>
              <h2>Publikationen</h2>
              <h3>{content.data.publikationensubtitle.text}</h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: content.data.publikationen.html,
                }}
              />
            </div>
          </div>
          <div style={{ marginTop: "-5em" }} className="container wide gray">
            {publications && publications.edges && (
              <Downloads data={publications.edges} />
            )}{" "}
          </div>
        </section>
      </DetailView>
    </>
  )
}

export default Publikationen

Publikationen.propTypes = {
  data: PropTypes.shape({
    publications: PropTypes.object.isRequired,
    content: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query PublikationenQuery($locale: String!) {
    content: prismicHomepage(lang: { eq: $locale }) {
      data {
        title {
          text
        }
        publikationensubtitle {
          text
        }
        publikationen {
          html
        }
        seo_publikationen_beschreibung {
          text
        }
        seo_publikationen_keywords {
          text
        }
      }
    }
    publications: allPrismicPublication(
      sort: { fields: [data___date], order: DESC }
      filter: { lang: { eq: $locale } }
    ) {
      edges {
        node {
          id
          data {
            title {
              text
            }
            date(formatString: "DD.MM.YYYY")
            subtitle {
              text
            }
            authors {
              text
            }
            file {
              url
              name
            }
          }
        }
      }
    }
  }
`
